<template>
  <div class="export-options">
    <el-tooltip
      content="Export to CSV"
      placement="top"
    >
      <t-icon
        :icon="['fal', 'file-csv']"
        size="sm"
        class="export-icon"
        border
        fixed-width
        @click="exportTo('csv')"
      />
    </el-tooltip>
    <el-tooltip
      content="Export to XLS"
      placement="top"
    >
      <t-icon
        :icon="['fal', 'file-excel']"
        size="sm"
        class="export-icon"
        border
        fixed-width
        @click="exportTo('xlsx')"
      />
    </el-tooltip>
    <el-tooltip
      content="Export to PDF"
      placement="top"
    >
      <t-icon
        :icon="['fal', 'file-pdf']"
        size="sm"
        class="export-icon"
        border
        fixed-width
        @click="exportTo('pdf')"
      />
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'ExportOptions',

  methods: {
    exportTo(format) {
      this.$emit('export', format);
    },
  },
};
</script>

<style
  scoped
  lang="stylus"
  rel="stylesheet/css"
>
  .export-options {
    display: grid;
    grid-template-columns: min-content min-content min-content;
    grid-gap: 3px;
  }

  .export-icon {
    cursor : pointer;
    transition: fill .25s ease;
  }

  .export-icon[disabled] {
    cursor: not-allowed;
  }

  .export-icon:hover {
    border-color: #b3b3b3;
  }
  .export-icon path {
    transition: fill .25s ease;
  }

  .fa-file-csv::v-deep path {
    fill: #aeaeae;
  }

  .fa-file-csv::v-deep:hover path {
    fill: #4a4a4a;
  }

  .fa-file-excel::v-deep path {
    fill: #67c23b;
  }

  .fa-file-excel::v-deep:hover path {
    fill: #2f5f29;
  }

  .fa-file-pdf::v-deep path {
    fill: crimson;
  }

  .fa-file-pdf::v-deep:hover path {
    fill: #6f101d;
  }

</style>
